<template>
  <div class="job-list-container">
    <b-card no-body>
      <b-card-header>
        <b-card-title>Lịch sử import</b-card-title>

      </b-card-header>
      <b-card-body>

        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped bordered"
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-job-detail
                    class="font-weight-bold"
                    @click="onEditItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>

                <!-- Column: Default -->
                <span v-else-if="props.column.field === 'status'">
                  <b-badge
                    :variant="props.row.statusObject.variant"
                    class="font-small-1"
                  >
                    <span>{{ props.row.statusObject.title }}</span>
                  </b-badge>
                </span>

                <!-- Column: Default -->
                <span v-else-if="props.column.field === 'time'">
                  {{ parseDateToString(props.row.created_at, 'DD-MM-YYYY HH:mm') }}
                </span>

                <!-- Column: Default -->
                <span v-else-if="props.column.field === 'fileLocation'">
                  <b-link
                    v-if="props.row.fileLocation"
                    variant="flat-info"
                    @click="downloadFile(props.row)"
                  >
                    <feather-icon
                      icon="DownloadCloudIcon"
                      class="mr-50 font-medium-3"
                    />
                    <span class="">Tải xuống</span>
                  </b-link>
                  <span v-if="!props.row.fileLocation && (props.row !== 'export_invoice_pdf' || props.row !== 'export_invoice_images') && props.row.status === 'pending'"><b-spinner
                    small
                    class="mr-1"
                    label="Small Spinner"
                  />Đang chờ</span>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    name="Nhà cung cấp"
                    modal="modal-job"
                    :hide-delete="!$can('delete', 'job')"
                    :hide-edit="!$can('update', 'job')"
                    @on-edit="onEditItem(props.row)"
                    @on-delete="onDelete(props.row)"
                  />

                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị tối đa </span>
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '20', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      trên tổng số {{ props.total }} kết quả
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  Không có bản ghi nào!
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- modal -->
    <job-detail-modal :job-id="item.id" />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BBadge,

} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import TableActions from '@/views/components/TableActions.vue';
// eslint-disable-next-line import/no-cycle
import { parseDateToString } from '@/auth/utils';
import useJobList from './useJobList';
import JobDetailModal from '../detail/JobDetailModal.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    TableActions,
    VueGoodTable,
    JobDetailModal,
    BBadge,
  },

  setup() {
    const {
      item,
      resetItem,
      columns,
      rows,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      downloadFile,
      fetchJobs,
      refetchJobs,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      onEditItem,
      onJobUpdated,
    } = useJobList();

    return {
      item,
      resetItem,
      columns,
      rows,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,

      refetchJobs,
      fetchJobs,
      downloadFile,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      onEditItem,
      parseDateToString,
      onJobUpdated,
    };
  },

  created() {
    this.fetchJobs();
  },
  sockets: {
    // importProcessUpdate(data) {
    // if (data && data.status === 'running' && data.items.length === 1) {
    //   this.fetchJobs();
    // } else if (data && (data.status === 'success' || data.status === 'fail')) {
    //   this.fetchJobs();
    // }
    // },
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
.job-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 100px !important;

            &:first-child {
              min-width: 50px !important;
            }
            &:nth-child(2) {
               min-width: 80px !important;
            }
            &:nth-child(3) {
               min-width: 200px !important;
            }
            &:nth-child(4) {
               min-width: 120px !important;
            }
            &:nth-child(5) {
               min-width: 150px !important;
            }
            &:nth-child(6) {
               min-width: 200px !important;
            }
            &:nth-child(7) {
               min-width: 150px !important;
            }
            &:nth-child(8) {
               min-width: 150px !important;
            }
          }
        }
      }
    }
}
</style>
